//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import chineseLanguageLoader from "chinese-language-loader/lib/main.js";
import Search from '@/components/Search';
import Product2 from '@/components/Product2';

export default {
    name: 'Product',
    components: { Search, Product2 },
    data() {
        return {
            uniacid: 0,
            webset: [],
            leimugoodslist: [],
            productInfo: null,
            goodsId: '',
            detail: '',
            goods: '',
            number: 1,
            color: '',
            size: '',
            location: '',
            goods_sku_key: '',
            locale: window.localStorage.getItem('locale') || this.$i18n.locale,
            currency: window.localStorage.getItem('currency'),
            productDetails: {
                17: {
                    "NS_01": {
                        name: "Slim Head Toothbrush",
                        detail: "Prefer an adult toothbrush with a small brush head. Suitable for consumers with sensitive throats and vomiting reactions. Ergonomic handle suitable for children and adults.<br /><br />TePe toothbrushes can heat the toothbrush neck with hot water, bend it back to the desired angle, and then rinse it with cold water to cool it down."
                    },
                    "NS_02": {
                        name: "Double Layer Bristle Toothbrush",
                        detail: "The TePe Supreme™ Double-Bristled Toothbrush features an innovative double-bristle design with fine, pointed bristles that clean deeply between teeth along the gum line, while shorter bristles gently clean the remaining tooth surfaces. At the same time, the toothbrush is equipped with a tapered brush head design and a stable, non-slip grip with thumb pads."
                    },
                    "NS_03": {
                        name: "Deep Cleansing Toothbrush (Super Soft Bristles)",
                        detail: "Prefer an adult toothbrush with a small brush head. Suitable for consumers with sensitive throats and vomiting reactions. Ergonomic handle suitable for children and adults.<br /><br />TePe toothbrushes can heat the toothbrush neck with hot water, bend it back to the desired angle, and then rinse it with cold water to cool it down."
                    },
                    "NS_04": {
                        name: "Deep Cleansing Toothbrush (Regular Bristles)",
                        detail: "The TePe Nova Deep Cleansing Toothbrush is equipped with specially pointed bristles to clean hard-to-reach areas of your mouth, especially the back teeth. At the same time, the tapered brush head design and anti-slip handle are ergonomic."
                    },
                    "NS_05": {
                        name: "Toothbrush (Soft Bristles)",
                        detail: "Double layer super soft bristles<br /><br />Long bristle design cleans deeply between teeth<br /><br />Short flat bristles clean tooth surfaces<br /><br />Tapered brush head reaches deep into back teeth<br /><br />Ergonomic handle design, also has anti-slip function<br /><br />The direct angle of the toothbrush head can be adjusted directly"
                    },
                    "NS_06": {
                        name: "Long Handle Interdental Brush - 0.6MM (pack of 6)",
                        detail: "The L-shaped design of the TePe Angle™ long-handled interdental brush makes it easier for the interdental brush to penetrate deeply into the interdental area for cleaning, especially the back teeth area. The slender and curved brush head can easily clean the back teeth and interdental spaces from the inside to the outside.<br /><br />Plus, the long, flat handle is ergonomically designed to help you grip the interdental brush.<br /><br />Each interdental brush wire is covered with safe plastic coating and will not irritate teeth."
                    },
                    "NS_07": {
                        name: "Long Handle Interdental Brush - 0.7MM (pack of 6)",
                        detail: "The L-shaped design of the TePe Angle™ long-handled interdental brush makes it easier for the interdental brush to penetrate deeply into the interdental area for cleaning, especially the back teeth area. The slender and curved brush head can easily clean the back teeth and interdental spaces from the inside to the outside.<br /><br />Plus, the long, flat handle is ergonomically designed to help you grip the interdental brush.<br /><br />Each interdental brush wire is covered with safe plastic coating and will not irritate teeth."
                    },
                    "NS_08": {
                        name: "Long Handle Interdental Brush - 0.5MM (pack of 6)",
                        detail: "The L-shaped design of the TePe Angle™ long-handled interdental brush makes it easier for the interdental brush to penetrate deeply into the interdental area for cleaning, especially the back teeth area. The slender and curved brush head can easily clean the back teeth and interdental spaces from the inside to the outside.<br /><br />Plus, the long, flat handle is ergonomically designed to help you grip the interdental brush.<br /><br />Each interdental brush wire is covered with safe plastic coating and will not irritate teeth."
                    }
                },
                24: {
    "SK_001": {
        "name": "Mini Storage for 12 months, size 4x3, 12 square feet"
    },
    "SK_002": {
        "name": "Mini Storage for 12 months, size 5x3, 15 square feet"
    },
    "SK_003": {
        "name": "Mini Storage for 12 months, size 4x4, 16 square feet"
    },
    "SK_004": {
        "name": "Mini Storage for 12 months, size 4x5, 20 square feet"
    },
    "SK_005": {
        "name": "Mini Storage for 12 months, size 5x6, 30 square feet"
    },
    "SK_007": {
        "name": "Smart Storage for 12 months, size 4x4, 16 square feet"
    }
},
                
            20: {
                "UF_001": {
                    name: "8 lessons of fencing course",
                    detail: "<p><strong>Participants aged 7 and above are welcome to join.</strong></p><p><br></p><p><strong>Time:</strong></p><p><strong>Friday: 17:00 - 19:00</strong></p><p><strong>Saturday: 17:00 - 19:00</strong></p><p><strong>Sunday: 16:00 - 18:00</strong></p><p><br></p><p><strong>Category: Course</strong></p>"
                },
                "UF_002": {
                    name: "Foil Fencing Beginner Class (Elementary School)",
                    detail: `<p><strong>Foil Fencing Beginner Class (Elementary School)</strong></p><p><strong>Open to ages 5 and above</strong></p><p><strong>Saturdays: 12:30 - 14:00 / 15:30 - 17:00</strong></p><p><strong>Sundays: 11:00 AM - 12:30 PM</strong></p><p><strong>Category: Course</strong></p>`
                },
                "UF_003": {
                    name: "Children's Fencing Course (Ages 3-6) - 8 Lessons",
                    detail: "<p><strong>Participants aged 5 and above are welcome to join.</strong></p><p><br></p><p><strong>Every Saturday: 12:30 - 14:00 / 15:30 - 17:00</strong></p><p><strong>Every Sunday: 11:00 AM - 12:30 PM</strong></p><p><br></p><p><strong>Category: Course</strong></p>"
                },
                "UF_004": {
                    name: "Adult Fencing Training Class",
                    detail: `<p><strong>Adult Fencing Training Class</strong></p><p><br></p><p><strong>Open to ages 14 and above</strong></p><p><br></p><p><strong>Tuesday 19:00 - 21:00</strong></p><p><strong>Wednesday 19:00 - 21:00</strong></p><p><br></p><p><br></p><p><strong>Category: Course</strong></p>`
                },
                "S5": {
                    name: "Sabre Body Wire",
                    detail: `<p><strong>Foil/Sabre Practice Hand Wire</strong></p><p><br></p><p><br></p><p><strong>Item Number: S5</strong></p><p><strong>Category: <span style="font-weight: 700;">Sword Accessories</span></strong></p><p><br></p>`
                },
                "S1": {
                    name: "Complete Sabre",
                    detail: `<div class="w1200 detail-content">
                                <p><span style="font-size: 16px;"><strong>Designed for high-level fencers.</strong></span></p>
                                <p><br></p>
                                <p><span style="font-size: 16px;"><strong>Item Number: S1</strong></span></p>
                                <p><span style="font-size: 16px;"><strong>Category:&nbsp;<span style="color: rgb(40, 40, 40);" pingfang="" sc",="" "helvetica="" neue",="" helvetica,="" "hiragino="" sans="" gb",="" "microsoft="" yahei",="" "微软雅黑,arial",="" "sans-serif";="" font-weight: 700; background-color: rgb(249, 249, 249); font-size: 16px;">Sword Accessories</span></strong></span></p>
                            </div>`
                },
                "S1-A": {
                    name: "Sabre Blade",
                    detail: `<div class="w1200 detail-content">
                                <p><span style="font-size: 16px;"><strong>Italian hand-forged</strong></span></p>
                                <p><span style="font-size: 16px;"><strong>- Top-grade martensitic aging steel</strong></span></p>
                                <p><span style="font-size: 16px;"><strong>The strict manufacturing process and multiple control procedures ensure that Alberto Franchini's blades are of the highest quality. Each blade's quality, hardness, and weight are manually controlled.</strong></span></p>
                                <p><br></p>
                                <p><span style="font-size: 16px;"><strong>Item Number: S1-A</strong></span></p>
                                <p><span style="font-size: 16px;"><strong>Category:&nbsp;<span style="color: rgb(40, 40, 40); font-weight: 700; background-color: rgb(249, 249, 249); font-size: 15px;">Sword Accessories</span></strong></span></p>
                            </div>`
                },
                "F1-A": {
                    name: "Foil Blade",
                    detail: `<div class="w1200 detail-content">
                                <p><strong>Allstar Foil Blade</strong></p>
                                <p><br></p>
                                <p><strong><span style="font-size: 16px;">Item Number: S1-B</span></strong></p>
                                <p><strong><span style="font-size: 16px;">Category:&nbsp;<span style="color: rgb(40, 40, 40); font-weight: 700; background-color: rgb(249, 249, 249); font-size: 16px;">Sword Accessories</span></span></strong></p>
                            </div>`
                },
                "S2": {
                    name: "Sabre Mask (350N)",
                    detail: `<div class="w1200 detail-content">
                                <p><strong>Removable liner</strong></p>
                                <p><br></p>
                                <p style="box-sizing: border-box; font-family: 'Roboto Condensed', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, 'Hiragino Sans GB', 'Microsoft YaHei', 微软雅黑, STHeiti, 'WenQuanYi Micro Hei', SimSun, sans-serif; margin-top: 0px; margin-bottom: 0px; line-height: normal; max-width: 100%; color: rgb(102, 102, 102); text-wrap: wrap; background-color: rgb(255, 255, 255);">
                                    <span style="color: rgb(0, 0, 0);">
                                        <strong><span style="box-sizing: border-box; max-width: 100%; font-weight: bold;">Features</span></strong>
                                    </span>
                                </p>
                                <ul style="box-sizing: border-box; font-family: 'Roboto Condensed', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, 'Hiragino Sans GB', 'Microsoft YaHei', 微软雅黑, STHeiti, 'WenQuanYi Micro Hei', SimSun, sans-serif; margin-bottom: 10px; max-width: 100%; color: rgb(102, 102, 102); text-wrap: wrap; background-color: rgb(255, 255, 255);" class="list-paddingleft-2">
                                    <li><p><span style="color: rgb(0, 0, 0);"><strong>Specialized practice/competition mask for fencing</strong></span></p></li>
                                    <li><p><span style="color: rgb(0, 0, 0);"><strong>The face-fitting liner is removable for easy cleaning</strong></span></p></li>
                                    <li><p><span style="color: rgb(0, 0, 0);"><strong>Puncture-resistant steel mesh</strong></span></p></li>
                                    <li><p><span style="color: rgb(0, 0, 0);"><strong>Penetration resistance tested to 350N, meeting the requirements of local secondary/primary schools or fencing association competitions in Hong Kong</strong></span></p></li>
                                    <li><p><span style="color: rgb(0, 0, 0);"><strong>Adjustable tension fastening device</strong></span></p></li>
                                    <li><p><span style="color: rgb(0, 0, 0);"><strong>Two secure fastening devices that comply with the latest mask regulations</strong></span></p></li>
                                    <li><p><span style="color: rgb(0, 0, 0);"><strong>Adjustable tightness to fit the shape of the face</strong></span></p></li>
                                </ul>
                                <p><span style="color: rgb(0, 0, 0);"><strong>Item Number: S2</strong></span></p>
                                <p><strong><span style="color: rgb(0, 0, 0);">Category:&nbsp;Mask</span></strong></p>
                                <p><strong><br></strong></p>
                            </div>`
                },
                "S4": {
                    name: "Electric Sabre Glove",
                    detail: `<div class="w1200 detail-content">
                                <p><strong>AF Electric Sabre Glove</strong></p>
                                <p><strong><br></strong></p>
                                <p><strong><br></strong></p>
                                <p><strong>Item Number: S4</strong></p>
                                <p><strong>Category:&nbsp;Gloves</strong></p>
                            </div>`
                },
            "SFIE-1": {
                name: "Leon Paul X-change FIE Traditional Sabre Mask",
                detail: `<div class="w1200 detail-content">
                            <p><strong>This mask is the choice of high-level fencing athletes. It is currently the lightest fencing mask on the market, featuring unique patented technology that allows it to be fully washable. The components are easy to upgrade and update, making it adaptable to future needs. It is equipped with a contour fitting system.</strong></p>
                            <p><br></p>
                            <p><br></p>
                            <p><strong>Item Number: SFIE-1</strong></p>
                            <p><strong>Category:&nbsp;Mask</strong></p>
                            <p><br></p>
                        </div>`
            },
"S7": {
    name: "Uhlmann 'World Cup' 800 N Women's Fencing Jacket",
    detail: `<div class="w1200 detail-content">
                <p><span style="font-size: 16px;"><strong>Women's fencing jacket suitable for training and beginners</strong></span></p>
                <p><strong><span style="font-size: 16px;"><br></span></strong></p>
                <p><strong><span style="font-size: 16px;">Item Number: S7</span></strong></p>
                <p><strong><span style="font-size: 16px;">Category:&nbsp;Fencing Jackets</span></strong></p>
                <p><strong><span style="font-size: 14px;"><br></span></strong><br></p>
            </div>`
},
"S8": {
    name: "Uhlmann 'Classic' 350 N Men's Fencing Jacket",
    detail: `<div class="w1200 detail-content">
                <p></p>
                <p><strong><span style="color: rgb(40, 40, 40); font-weight: 700; font-size: 16px;">Men's fencing jacket</span> suitable for training and beginners</strong></p>
                <p><br></p>
                <p><strong>Item Number: S8</strong></p>
                <p><strong>Category:&nbsp;Fencing Jackets</strong></p>
            </div>`
},
"S9": {
    name: "Allstar Ecostar FIE Women's Fencing Pants",
    detail: `<div class="w1200 detail-content">
                <p><strong><span style="font-size: 15px;">The Ecostar pants are an excellent choice for children, beginners, and non-professional athletes due to their superior characteristics. The single-layer, full-elastic fabric combines high wearing comfort with excellent freedom of movement. Ecostar fencing pants offer the best quality and safety at an affordable price.</span></strong></p>
                <p><br></p>
                <p><strong><span style="font-size: 15px;">Item Number: S9</span></strong></p>
                <p><strong><span style="font-size: 15px;">Category:&nbsp;Fencing Pants</span></strong></p>
                <p><br></p>
            </div>`
},
"S10": {
    name: "Allstar Ecostar FIE Men's Fencing Pants",
    detail: `<div class="w1200 detail-content">
                <p><strong><span style="font-size: 15px;">The Ecostar pants are an excellent choice for children, beginners, and non-professional athletes due to their superior characteristics. The single-layer, full-elastic fabric combines high wearing comfort with excellent freedom of movement. Ecostar fencing pants offer the best quality and safety at an affordable price.</span></strong></p>
                <p><br></p>
                <p><strong><span style="font-size: 15px;">Item Number: S10</span></strong></p>
                <p><strong><span style="font-size: 15px;">Category:&nbsp;Fencing Pants</span></strong></p>
                <p><br></p>
            </div>`
},
"S11": {
    name: "Uhlmann Glove Combination 'Basic' 2.0",
    detail: `<div class="w1200 detail-content">
                <p><strong>Uhlmann Glove Combination "Basic" 2.0</strong></p>
                <p><br></p>
                <p><br></p>
                <p><strong>Item Number: S11</strong></p>
                <p><strong>Category:&nbsp;Gloves</strong></p>
            </div>`
},
"S12": {
    name: "Uhlmann Combination Power Glove",
    detail: `<div class="w1200 detail-content">
                <p><strong>Uhlmann Combination Power Glove</strong></p>
                <p><br></p>
                <p><br></p>
                <p><strong>Item Number: S12</strong></p>
                <p><span style="font-size: 16px;"><strong>Category</strong></span><strong><span style="font-size: 16px;">: <span style="font-size: 16px; color: rgb(40, 40, 40); font-weight: 700; text-wrap: wrap; background-color: rgb(249, 249, 249); font-family: arial, helvetica, sans-serif;">Gloves</span></span></strong></p>
            </div>`
},
"S13": {
    name: "Leon Paul Standard Adult Electric Foil Grip",
    detail: `<div class="w1200 detail-content">
                <p><strong><span style="font-size: 16px;">Leon Paul Standard Adult Electric Foil Grip</span></strong></p>
                <p><br></p>
                <p><br></p>
                <p><strong><span style="font-size: 16px;">Item Number: S13</span></strong></p>
                <p><strong><span style="font-size: 15px;">Category: <span style="color: rgb(40, 40, 40); font-weight: 700; text-wrap: wrap; background-color: rgb(249, 249, 249); font-family: arial, helvetica, sans-serif;">Sword Accessories</span></span></strong></p>
            </div>`
},
"S15": {
    name: "Leon Paul Heavy Sabre Grip",
    detail: `<div class="w1200 detail-content">
                <p><strong>The most popular heavy sabre grip in the world. Made from solid aluminum casting, it is conductive and thus suitable for heavy sabre. This design offers incredible control between the thumb and fingers as well as strong stability for the wrist.</strong></p>
                <p><br></p>
                <p><strong>Item Number: S15</strong></p>
                <p><strong>Category: <span style="color: rgb(40, 40, 40); font-weight: 700; text-wrap: wrap; background-color: rgb(249, 249, 249); font-family: arial, helvetica, sans-serif;">Sword Accessories</span></strong></p>
            </div>`
},
"S16": {
    name: "Uhlmann Heavy Sabre Straight Grip Electric Blade, BF FIE Blue Blade",
    detail: `<div class="w1200 detail-content">
                <p><span style="font-size: 16px; color: rgb(0, 0, 0);"><strong><span style="font-size: 16px;">Uhlmann Heavy Sabre Straight Grip Electric Blade</span></strong></span></p>
                <p><span style="font-size: 16px;"><br></span></p>
                <p><span style="background-color: rgb(255, 255, 255);"><strong>BF blade is forged from spring steel, and FIE international competitions designate the use of BF blades for their superior flexibility and durability compared to standard blades.</strong></span></p>
                <p><span style="font-size: 16px;"><br></span></p>
                <p><strong>Item Number: S16</strong></p>
                <p><strong>Category: <span style="font-family: Roboto, helvetica, sans-serif;">Blade</span></strong></p>
            </div>`
},
"S17": {
    name: "BF FIE Heavy Sabre Straight Grip Electric Blade",
    detail: `<div class="w1200 detail-content">
                <p><strong><span style="font-size: 16px;">BF - Blade</span></strong></p>
                <p><br></p>
                <p><strong><span style="font-size: 16px;">The most popular FIE blade on the market. Made from spring steel, it offers high durability, excellent balance, and light weight. Available in different hardness options.</span></strong></p>
                <p><br></p>
                <p><strong><span style="font-size: 16px;">Item Number: S17</span></strong></p>
                <p><strong><span style="font-size: 16px;">Category: <span style="color: rgb(40, 40, 40);">Blade</span></span></strong></p>
                <p><br></p>
            </div>`
},
"S18": {
    name: "Allstar Fencing Bag",
    detail: `<div class="w1200 detail-content">
                <p><strong>Allstar <span style="color: rgb(51, 51, 51);">The zippers, edges, and straps of the fencing bag are all black.</span></strong></p>
                <p><strong><span style="color: rgb(51, 51, 51);"><br></span></strong></p>
                <p><strong><span style="color: rgb(51, 51, 51);"><br></span></strong></p>
                <p><strong><span style="color: rgb(51, 51, 51);">Item Number: S18</span></strong></p>
                <p><strong><span style="color: rgb(51, 51, 51);">Category: <strong style="text-wrap: wrap;"><span style="color: rgb(51, 51, 51);">Fencing Bag</span></strong></span></strong></p>
            </div>`
},
"S19": {
    name: "Leon Paul Team Bag",
    detail: `<div class="w1200 detail-content">
                <p><strong>The first wheeled fencing bag designed to stand upright. It features three zippered compartments on the outside of the bag, and the bag is secured on top of the wheelbase with straps.</strong></p>
                <p><br></p>
                <p><br></p>
                <p><strong>Item Number: S19</strong></p>
                <p><strong>Category: <span style="text-wrap: wrap;">Fencing Bag</span></strong></p>
            </div>`
},
"S20": {
    name: "Uhlmann Treasure Fencing Cart",
    detail: `<div class="detail-info">
                <div class="w1200 content">Product Details</div>
                <div class="w1200 detail-content">
                    <p><strong>The Treasure Fencing Cart includes a cart and a fencing bag. The cart features three zippered compartments on the outside, and the bag is secured on top of the cart with straps.</strong></p>
                    <p><br></p>
                    <p><strong>Item Number: S20</strong></p>
                    <p><strong>Category: <span>Fencing Bag</span></strong></p>
                </div>
            </div>`
},
"S21": {
    name: "Uhlmann FIE 1600N Extra Sabre Mask",
    detail: `<div class="detail-info">
                <div class="w1200 content">Product Details</div>
                <div class="w1200 detail-content">
                    <p><strong>Removable and replaceable padding</strong></p>
                    <p><br></p>
                    <p><br></p>
                    <p><strong>Item Number: S21</strong></p>
                    <p><strong>Category: <span>Mask</span></strong></p>
                </div>
            </div>`
},
"S22": {
    name: "Allstar Comfort FIE Sabre Mask",
    detail: `<div class="w1200 detail-content">
                <p><strong>Allstar Inox FIE mask offers the highest level of safety and perfect wearing comfort.</strong></p>
                <p><strong>All FIE masks produced have a 1600N bib for maximum protection.</strong></p>
                <p><strong>The Inox mask is lightweight, with a soft padding ensuring excellent wearing comfort and safety.</strong></p>
                <p><br></p>
                <p><strong>Item Number: S22</strong></p>
                <p><strong>Category: <span>Mask</span></strong></p>
                <p><br></p>
            </div>`
},
"S23": {
    name: "Allstar Inox FIE Foil Mask",
    detail: `<div class="w1200 detail-content">
                <p><strong>Allstar Inox FIE mask offers the highest level of safety and perfect wearing comfort.</strong></p>
                <p><strong>All FIE masks produced have a 1600N bib for maximum protection.</strong></p>
                <p><strong>The Inox mask is lightweight, with a soft padding ensuring excellent wearing comfort and safety.</strong></p>
                <p><br></p>
                <p><strong>Item Number: S23</strong></p>
                <p><strong>Category: <span>Mask</span></strong></p>
            </div>`
},
"S24": {
    name: "Uhlmann FIE 1600N Variously Extra Dual Mask",
    detail: `<div class="w1200 detail-content">
                <p><strong>The mask for épée can be converted to a foil mask with the addition of a conductive fabric.</strong></p>
                <p><strong>The inner padding is removable and replaceable.</strong></p>
                <p><br></p>
                <p><strong>Item Number: S24</strong></p>
                <p><strong>Category: Mask</strong></p>
            </div>`
},

"S14": {
    name: "Leon Paul Standard Epee Electric Blade - Handle",
    detail: `<div class="w1200 detail-content">
                <p><strong>Leon Paul Standard Epee Electric Blade - Handle</strong></p>
                <p><br></p>
                <p><br></p>
                <p><strong>Item Number: S14</strong></p>
                <p><strong>Category: Blade Accessories</strong></p>
                <p><br></p>
            </div>`
        },

"S4-A": {
    name: "Uhlamnn Electric Sabre cuff",
    detail: `<div class="w1200 detail-content">
                <p><strong>Uhlmann Electric Sabre Cuff</strong></p>
                <p><br></p>
                <p><br></p>
                <p><strong>Item Number: S4-A</strong></p>
                <p><strong>Category: Cuff</strong></p>
            </div>`
},
"E1-A": {
    name: "Epee Blade",
    detail: `<div class="w1200 detail-content">

            </div>`
        },
"U1": {
    name: "Fencing Jacket",
    detail: `<div class="w1200 detail-content">

            </div>`
        },
"U2": {
        name: "Fencing Underplastron (CE350N)",
        detail: `<div class="w1200 detail-content">

                </div>`
        },
        "U2-FIE": {
        name: "Fencing Underplastron (FIE800N)",
        detail: `<div class="w1200 detail-content">

                </div>`
        }
            }
        }
    };
},
    created() {
        this.goodsId = this.$route.query.id;
        const ss = window.localStorage.getItem('webinfo');
        const ssarray = JSON.parse(ss);

        this.webset = ssarray;
        this.uniacid = ssarray['ucid'];

        this.getGoodsDetail();
        this.getGoodsListT("Luggage", "");
    },
    computed: {
        productNameAndDetails() {
            if (this.productInfo && this.productInfo.basic && this.productInfo.basic.field) {
                const uniacid = this.uniacid;
                const goodsId = this.productInfo.basic.field.goods_id;

                if (this.productDetails[uniacid] && this.productDetails[uniacid][goodsId]) {
                    return this.productDetails[uniacid][goodsId];
                }
            }
          
        }
    },
    methods: {
        // 下单
        orderConfirm() {
            this.onConfirmClick('buy');
        },

        async onConfirmClick(code) {
            const res = await this.$http.post('/cart/add', {
                size: this.$refs.sizeselect.value,
                user_id: window.localStorage.getItem('user_id'),
                goods_id: this.goodsId,
                goods_sku_key: this.productInfo.sku.list[0].goods_sku_key,
                goods_number: this.number,
                color: this.$refs.colorselect.value,
                location: this.$refs.locationselect.value,
                promotion_code: this.productInfo.basic.field.promotion_code,
                is_checked: 1,
                is_direct: code === 'buy' ? 1 : 0
            });

            if (res.code !== 0) {
                return this.$message.error(res.message);
            } else {
                if (code === 'buy') {
                    this.$router.push({ name: 'orderConfirm', params: { cart_id: res.data.cart_id } });
                }
            }
        },

        // 修改数量
        changeNumber(type) {
            if (type === 'add') {
                this.number += 1;
            } else {
                this.number > 1 && (this.number -= 1);
            }
        },

        // 获取商品信息
        async getGoodsDetail() {
            if (!this.goodsId) return;
            const res = await this.$http.get('/goods/detail', {
                goods_id: this.goodsId
            });

            const data = res.data;
            data.basic.field.detail_mobile = JSON.parse(data.basic.field.detail_mobile);
            this.detail = data.basic.field.detail;
            this.goods = data.basic.field;
            this.productInfo = res.data;

            const uniacid = this.uniacid;

            if (this.locale === 'zh-CN') {
                const goods_name = chineseLanguageLoader(this.productInfo.basic.field.goods_name, { language: 'zh-CN' });
                this.productInfo.basic.field.goods_name = goods_name;

                const detail = chineseLanguageLoader(this.productInfo.basic.field.detail, { language: 'zh-CN' });
                this.productInfo.basic.field.detail = detail;
            } else if (this.locale === 'EN') {
                const productDetails = this.productNameAndDetails;
                this.productInfo.basic.field.goods_name = productDetails.name;
                this.productInfo.basic.field.detail = productDetails.detail;
            }
        },

        // 添加到购物车
        async addCart(code) {
            let selectedsize = '';

            if (this.uniacid === 20 && this.goodsId === 'S11') {
                selectedsize = this.$refs.sizeselect.value.toString();
            }

            const res = await this.$http.post('/cart/add', {
                user_id: window.localStorage.getItem('user_id'),
                goods_id: this.goodsId,
                goods_sku_key: this.productInfo.sku.list[0].goods_sku_key,
                goods_number: this.number,
                size: selectedsize,
                color: this.$refs.colorselect.value.toString(),
                promotion_code: this.productInfo.promotion_code,
                is_checked: 1,
                is_direct: code === 'buy' ? 1 : 0
            });

            if (res.code !== 0) {
                if (res.data.code === 401) {
                    window.location.href = '/#/login';
                    return false;
                }
                return this.$message.error(res.message);
            } else {
                this.$message({ message: '添加成功', type: 'success' });
            }
        },


        async getGoodsListT(one, two) {
            switch (this.uniacid) {
                case 4:
                    one = "A11";
                    break;
                case 5:
                    one = "none";
                    break;
                case 6:
                    one = "Curtain rod";
                    break;
                case 7:
                    one = "AB";
                    break;
                case 8:
                    one = "warehouse";
                    break;
                case 9:
                    one = "jiankang";
                    break;
                case 10:
                    one = "YTOFFICE";
                    break;
                case 11:
                    one = "無綫連接儀錶 ( 外置藍牙模組 )";
                    break;
                case 12:
                    one = "15g Tube";
                    break;
                case 14:
                    one = "Rake";
                    break;
                case 15:
                    one = "消毒儀器";
                    break;
                case 16:
                    one = "新產品";
                    break;
                case 17:
                    one = "專業牙刷系列";
                    break;
                case 18:
                    one = "Toy Car";
                    break;
                case 19:
                    one = "Discount item";
                    break;
                case 20:
                    one = "課程";
                    break;
                case 21:
                    one = "Insurance Products";
                    break;
                case 22:
                    one = "Custom-made";
                    break;
                case 23:
                    one = "tea";
                    break;
                case 24:
                    one = "Storage";
                    break;
                case 25:
                    one = "小電器";
                    break;
                case 26:
                    one = "Grain Moisture Meter";
                    break;
                case 27:
                    one = "Insurance Services";
                    break;
                case 28:
                    one = "Financial Advisory Services";
                    break;
                case 29:
                    one = "倫敦";
                    break;
                case 30:
                    one = "Earphones";
                    break;
                case 31:
                    one = "原創麵譜";
                    break;
                default:
                    one = "none";
                    break;
            }
            const res = await this.$http.get('/goods', {
                page: 0,
                size: 5,
                category_one: one,
                category_two: two,
            });
            console.log('resgoods', res);

            
            this.isLoading = false;
            if (res.code !== 0) return;
            this.leimugoodslist = res.data.list;
            
            console.log("leimugoodslist: " + this.uniacid);
            console.log(this.leimugoodslist);
        },
    }
};

