//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import chineseLanguageLoader from "chinese-language-loader/lib/main.js";
export default {
    props: ['product'],
    data() {
        return {
            locale: window.localStorage.getItem('locale') || this.$i18n.locale
        }
    },
    created() {	
        let uniacid =window.localStorage.getItem('uniacid');
        //let uniacid =19;
        if(this.locale === 'zh-CN') {
            const str = chineseLanguageLoader(this.product.goods_name, { language: 'zh-CN' });
            this.product.goods_name= str;
        }
        else if(this.locale === 'EN' && uniacid == 17) {
            if(this.product.goods_id === "NS_01") {
                this.product.goods_name= "Slim Head Toothbrush";
            }
            else if(this.product.goods_id === "NS_02") {
                this.product.goods_name= "Double Layer Bristle Toothbrush";
            }
            else if(this.product.goods_id === "NS_03") {
                this.product.goods_name= "Deep Cleansing Toothbrush (Super Soft Bristles)";
            }
            else if(this.product.goods_id === "NS_04") {
                this.product.goods_name= "Deep Cleansing Toothbrush (Regular Bristles)";
            }
            else if(this.product.goods_id === "NS_05") {
                this.product.goods_name= "Toothbrush (Soft Bristles)";
            }
            else if(this.product.goods_id === "NS_06") {
                this.product.goods_name= "Long Handle Interdental Brush - 0.6MM (pack of 6)";
            }
            else if(this.product.goods_id === "NS_07") {
                this.product.goods_name= "Long Handle Interdental Brush - 0.7MM (pack of 6)";
            }
            else if(this.product.goods_id === "NS_08") {
                this.product.goods_name= "Long Handle Interdental Brush - 0.5MM (pack of 6)";
            }
        }
        else if(this.locale === 'EN' && uniacid == 20) {
            if(this.product.goods_id === "UF_001") {
                this.product.goods_name= "Sabre Course 8 Sessions";
            }
            else if(this.product.goods_id === "UF_002") {
                this.product.goods_name= "Foil Beginner Class</br> (Primary School)";
            }
            else if(this.product.goods_id === "UF_003") {
                this.product.goods_name= "Children's Fencing Course (Ages 3-6) 8 Sessions";
            }
            else if(this.product.goods_id === "UF_004") {
                this.product.goods_name= "Adult Fencing Training </br>Class";
            }}

            else if(this.locale === 'EN' && uniacid == 24) {
                if(this.product.goods_id === "SK_001") {
        this.product.goods_name = "Mini storage for 12 months, size 4x3, 12 square feet";
    }
    else if(this.product.goods_id === "SK_002") {
        this.product.goods_name = "Mini storage for 12 months, size 5x3, 15 square feet";
    }
    else if(this.product.goods_id === "SK_003") {
        this.product.goods_name = "Mini storage for 12 months, size 4x4, 16 square feet";
    }
    else if(this.product.goods_id === "SK_004") {
        this.product.goods_name = "Mini storage for 12 months, size 4x5, 20 square feet";
    }
    else if(this.product.goods_id === "SK_005") {
        this.product.goods_name = "Mini storage for 12 months, size 5x6, 30 square feet";
    }
    else if(this.product.goods_id === "SK_007") {
        this.product.goods_name = "Smart storage for 12 months, size 4x4, 16 square feet";
    }
    }},
    methods: {
        detail(id) {
            this.$router.push({ path:'/product', query:{id}})
        }
    }
}
